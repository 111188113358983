*::-webkit-scrollbar {
  width: 8px;
}

*::-webkit-scrollbar-track {
  background: #f1f1f1;
  border-radius: 10px;
}

*::-webkit-scrollbar-thumb {
  background-color: #4a90e2;
  border-radius: 10px;
}

* {
  scrollbar-width: thin;
  scrollbar-color: #4a90e2 #f1f1f1;
}

.terms ul {
  list-style-type: disc;
  margin-left: 30px;
}

.terms li {
  margin-bottom: 10px;
}